@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.bloglist {

  &__title {
    @include heading-base;
  }

  &__wrapper {
    flex-direction: column;
    display: flex;
    margin-bottom: $spacer*4;
  }

  &__col {
    width: 100%;

    .teasercard {
      padding: $spacer*1.5 0;
      padding-bottom: 0;
    }
    
    @include media-breakpoint-up(lg) {
      &:first-of-type {
        width: 100%;
      }
    }
  }
}

div[class^="col"] .bloglist {
  &__wrapper {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: column;
    }
  }

  &__col {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
}

/* Blog list grid border left */
@include media-breakpoint-up(md) {
  .paragraph__bloglist {

    .display-in-grid {
      .col-md-4 {
        border-left: 1px solid var(--sp-color-neutral-accent-one);
        margin-left: 13px;
        margin-bottom: 30px;
        padding-left: 20px;
        width: 31%;

        .teasercard {
          margin-top: -25px;
        }
      }
    }

  }
}