@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__hero-image {
  .hero-image {
    text-align: center;
    margin: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    min-height: 500px;
    display: flex;
    align-items: center;

    .paragraph__icon {
      font-size: 26px;
      position: relative;
      transform: rotate(180deg);
      transition: all .5s ease-out;
    }

    &:hover {
      .paragraph__icon {
        transform: translateX(7px) rotate(180deg);
      }
    }
  }
}
