@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__faqlist {
  h3.heading {
    font-weight: 600;
  }
  .faq-card-wrapper {
    margin-bottom: $spacer*2;
  }
}
