@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__spacer {
  div {
    display: flex;
    align-items: center;
    min-height: 20px;
  }

  hr {
    margin-top: 0;
    border: 0;
    border-top: 4px solid $yellow;
    width: 80%;
    opacity: 1;
  }
}

// fix sibling spacing
.paragraph__spacer + .paragraph__wysiwyg {
  padding-top: 0;
}