@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.announcement__wrapper {
  font-family: var(--sp-font-secondary);

  a {
    color: darken($color: $blue, $amount: 5);
  }

  .announcement__priority {
    position: fixed;
    text-indent: -10000px;
  }
  .announcement__content {
    transition: padding 150ms;
    font-size: 20px;
    line-height: 1.6;
    padding-top: 1.2em;
    padding-bottom: 1.2em;

    .announcement__icon {
      display: block;
      float: left;
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }
    p {
      margin: 0;
    }
    a {
      text-decoration: underline;
      padding: 0 0.25em;
      transition: all 0.1s ease-out;
    }
  }

  &.announcement__wrapper--Call-to-action {
    background-color: $light-green;
    color: $body-text;

    .announcement__icon {
      background: url("./../../../images/cta_icon.svg") center center no-repeat;
      background-size: 30px 30px;
    }
  }
  &.announcement__wrapper--Informational {
    background-color: $medium-gray;
    color: $white;

    .announcement__icon {
      background: url("./../../../images/info_icon.svg") center center no-repeat;
      background-size: 30px 30px;
    }

    .announcement__close {
      background: url("./../../../images/close_icon_white.svg") center center
        no-repeat;
    }

    a {
      color: #FFE285;
    }
  }

  &.announcement__wrapper--Warning {
    background-color: $yellow;
    color: $dark-gray;

    .announcement__icon {
      background: url("./../../../images/warning_icon.svg") center center
        no-repeat;
      background-size: 30px 30px;
    }
  }

  .announcement__close {
    width: 24px;
    background: url("./../../../images/close_icon.svg") center center no-repeat;
    background-size: 24px 24px;
    border: none;
    color: $white;
    vertical-align: middle;
    @media (max-width: 575px) {
      margin-right: 0.5em;
    }
  }
}
