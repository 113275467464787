@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.status-info {
  padding: $spacer;
  background-color: #B10577;
  text-align: center;
  color: $white;
  font-family: var(--sp-font-secondary);
  font-weight: 600;

  a {
    border: solid 1px $white;
    display: inline-block;
    margin: auto $spacer;
    color: $white;
    font-weight: 600;

    &:hover {
      background-color: $white;
      color: var(--sp-color-secondary);
    }
  }
}