@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.copyright__logo {
  display: inline-block;
  margin-right: $spacer*1.5;
  margin-bottom: $spacer;
  height: 43px;
  
  @include media-breakpoint-up(md) {
    margin-bottom: none;  
  }
}

.copyright {
  display: inline-block;
  font-size: 0.9rem;
  color: $light-blue;
  margin-left: .5rem;
}
