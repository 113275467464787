@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";

.paragraph__eventlist {
  margin-bottom: $spacer * 2;

  @include media-breakpoint-up(md) {

    .event-card-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
    
    .event-card {
      max-width: 300px;
      flex: 1 1 auto;
    }
  }
}

.paragraph__twocolumn, 
.paragraph__threecolumn {
  .paragraph__eventlist {
    .event-card-wrapper {
      display: block;
    }
    .event-card {
      max-width: 100%;
    }
  }  
}