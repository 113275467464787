@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.centennial-brand-bar--black {
  background: #000;
  background: linear-gradient(180deg, rgba(26, 26, 32, 1) 90%, rgba(0, 0, 0, 1) 100%);
  text-align: center;
  padding: 5px 0;
}

.centennial-brand-bar--black .centennial-brand-bar__link {
  display: inline-block;
  padding: 5px;
  position: relative;
  vertical-align: middle;
}

.centennial-brand-bar--black .centennial-brand-bar__link:before {
  content: "";
  background: radial-gradient(farthest-side at 50% 110%, rgba(255, 255, 255, .4) 0%, rgba(255, 255, 255, 0) 100%);
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease-in-out;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.centennial-brand-bar--black .centennial-brand-bar__link:hover:before {
  opacity: 1;
}

.centennial-brand-bar--black .centennial-brand-bar__logo {
  display: block;
  position: relative;
  z-index: 3;
  width: 147px;
  height: 40px
}