@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
/* Project list grid border left */
@include media-breakpoint-up(md) {
  .paragraph__projectlist {

    .display-in-grid {
      .col-lg-4 {
        margin-bottom: 20px;
      }

      .col-lg-8, .col-lg-12 {
        border-left: 1px solid var(--sp-color-neutral-accent-one);
        margin-left: 13px;
        padding-left: 20px;

        .heading {
          margin-top: -10px;
        }

      }
    }

  }
}