@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__specialtext {
    font-family: var(--sp-font-primary);
    font-size: 24px;
    font-weight: 300;
    color: var(--sp-color-primary);
    line-height: 1.5;
}

.paragraph__specialtext {
    p {
      a {
        color: var(--sp-color-link);
        border-bottom: 1px solid #ccc;
  
        &:hover {
          color: var(--sp-color-primary);
          border-bottom: 1px solid #012169;
          text-decoration: none;
        }
      }
    }
  }