@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.page-card {
  padding: 1em 0 .8em 0;
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);

  @include media-breakpoint-up(md) {
    display: flex;
  }

  &:last-of-type {
    border-bottom: none;
  }

  h4 {
    margin-top: 0;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 24px;
  }

  .image-wrapper {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 200px;
      padding-right: $spacer;
    }

    .page-thumbnail-image {
      max-width: 250px;
      @include media-breakpoint-up(md) {
        max-width: 168px;
      }
    }

  }

  &.display-in-grid {
    @include media-breakpoint-up(md) {
      display: block;
    }

    .heading {
      padding-top: 10px;
    }

    @include media-breakpoint-up(md) {
      .image-wrapper {
        width: unset !important;
      }
    }

    .page-thumbnail-image {
      max-width: 100%;
    }
  }

}

/* Page list grid border left */
@include media-breakpoint-up(md) {
  .paragraph__pagelist {

    .col-md-4 {
      .display-in-grid {
        .image-wrapper {
          padding-bottom: 20px;
        }

        .text-wrapper {
          border-left: 1px solid var(--sp-color-neutral-accent-one);
          padding-left: 20px;

          .heading {
            padding-top: 0;
          }
        }
      }
    }

  }
}