@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.footer-menu {
  #footermenulabel {
    color: $white;
  }

  ul {
    padding: $spacer 0 0 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
			padding: 0;
      &:empty {
        padding: 0;
      }
    }

    .nav-item {
      .nav-link {
				padding: $spacer*0.5 0;
        color: var(--sp-color-footer-text);
        text-decoration: underline;
        text-decoration-thickness: 0.7px;

				@include media-breakpoint-up(md) {
					padding-top: 0;
				}

        &:hover {
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}

.footer-bottom-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  padding-top: $spacer;

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  a {
    font-size: 0.9rem;
    margin-left: $spacer;
    text-decoration: underline;

    @include media-breakpoint-up(md) {
      text-decoration: none;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:hover {
      text-decoration: underline;
      color: $white;
    }
  }
}