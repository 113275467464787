@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.project {
  &-timeline {
    font-family: var(--sp-font-secondary);
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 0;
  }

  &-timeline-details {
    word-wrap: break-word;
  }

  &-status {
    font-style: italic;
    margin-top: 0;
    font-family: var(--sp-font-secondary);
  }

  &-header {
    background: var(--sp-color-primary);
    padding: 35px;

    &.has-image {
      background: linear-gradient(
        90deg,
        $white 35%,
        var(--sp-color-primary) 35%
      );
      padding: 35px 35px 35px 0;

      @include media-breakpoint-down(lg) {
        background: none;
        padding: 0;
      }
    }

    @include media-breakpoint-down(lg) {
      background: none;
      padding: 0;
    }

    .row {
      margin: 25px 0;
      @include media-breakpoint-down(lg) {
        margin: 0;
      }
    }

    h1 {
      @include media-breakpoint-up(lg) {
        color: $white;
        margin-top: 0;
        font-size: 40px;
      }
    }

    &-text {
      align-self: center;
    }
  }
}

.category {
  font-family: var(--sp-font-secondary);
  font-size: 14px;
  color: var(--sp-color-text);
}

.related-projects-container {
  border-top: 1px solid var(--sp-color-neutral-accent-one);
  margin-top: $spacer * 3;
  font-family: var(--sp-font-secondary);

  @include media-breakpoint-down(sm) {
    margin-top: $spacer * 2;
  }

  .related-projects-list-line {
    border-top: 4px solid var(--sp-color-accent-one);
    content: "";
    width: 150px;

    @include media-breakpoint-down(sm) {
      border-top: 5px solid var(--sp-color-accent-one);
    }

  }

  h3 {
    margin-top: 8px;
  }

  ul {
    list-style: none;
    padding: 0;


    li {
      list-style: none;
      padding: $spacer * 1.5 0;
      width: 100%;
      border-bottom: 1px solid var(--sp-color-neutral-accent-one);

      @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: auto 1fr;
      }

      img {
        margin-right: $spacer * 2;
        margin-bottom: $spacer;
        width: 300px;
        height: 185px;
        object-fit: cover;
      }

      &:last-of-type {
        border-bottom: none;
      }

      .related-project {
        &-timeframe {
          font-size: 14px;
          font-weight: 600;
          line-height: 1.71;
          color: var(--sp-color-text);
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        &-title {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.6;
          color: var(--sp-color-secondary);
          margin-bottom: 20px;
        }

        &-summary {
          margin-top: $spacer;
        }
      }
    }
  }
}

.node-teaser__project {
  padding: $spacer*1.5 0; 
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  word-break: break-word;

  &:last-of-type {
    border-bottom: none;
  }


  .heading--teaser {
    text-transform: capitalize;
  }

  .node-teaser__date {
    text-transform: capitalize;
    font-family: var(--sp-font-secondary);
    font-weight: 600;
    margin-bottom: $spacer * 0.7;
  }
}

.search-results {
  .node-teaser__project {
      border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .col-md-4 {
    .paragraph__projectlist .node-teaser__project, .node-teaser__project {
      flex-direction: column;
      .col-lg-4,
      .col-lg-8 {
        width: 100%;
        // padding-bottom: $spacer * 1.5;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}

.node-teaser__project.display-in-grid {
  .col-lg-4, .col-lg-8 {
    width: 100%;
  }

  .heading--teaser {
    padding-top: 10px;
  }
}