@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.eyebrow {
  text-transform: uppercase;
  font-family: var(--sp-font-secondary);
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 0.9rem;
  color: $dark-gray;

  &--warning {
    color: $orange;
    text-transform: capitalize;
    font-style: italic;
    margin-bottom: 0;
  }
}