@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


@mixin button-style($button-color) {
  border: 1px solid $button-color;
  background: $button-color;

  &:hover {
    background-color: darken($button-color, 10%);
    text-decoration: none;
  }
}

.button,
a.button {
  border-radius: 0;
  color: var(--sp-color-button-primary-text);
  background-color: var(--sp-color-button-primary-background);
  margin: $spacer 0;
  padding: $spacer*0.3 $spacer*0.5;
  border: 4px solid var(--sp-color-button-primary-border);
  display: inline-flex;
  align-items: center;

  @include media-breakpoint-up(sm) {
    padding: $spacer*0.5 $spacer*1.3;
  }

  &:hover {
    background-color: var(--sp-color-button-primary-background-hover);
    transition: background-color 0.2s ease-out;
    color: var(--sp-color-button-primary-text-hover);
    text-decoration: none;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.4);

    .button__icon {
      transform: translateX(7px) rotate(180deg);
      transition: all 0.2s ease-out;
    }
  }

  &__text, span {
    font-family: var(--sp-font-secondary);
    font-size: 0.9rem;
    font-weight: 700;
    letter-spacing: 0.96px;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }

  &--cta {
    @extend .button;
    color: var(--sp-color-button-cta-text);
    border: 1px solid var(--sp-color-button-cta-border);
    background-color: var(--sp-color-button-cta-background);

    &:hover {
      background-color: var(--sp-color-button-cta-background-hover);
      border: 1px solid var(--sp-color-button-cta-border-hover);
      color: var(--sp-color-button-cta-text-hover);
      text-decoration: none;
      
    }
  }

  &--footer {
    @extend .button;
    color: var(--sp-color-button-footer-text);
    border: 1px solid var(--sp-color-button-footer-border);
    background-color: var(--sp-color-button-footer-background);

    &:hover {
      background-color: var(--sp-color-button-footer-background-hover);
      border: 1px solid var(--sp-color-button-footer-border-hover);
      color: var(--sp-color-button-footer-text-hover);
      text-decoration: none;
    }    
  }

  &--plain {
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: transparent;
      text-decoration: none;
      box-shadow: none;
    }
  }

  &--white {
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &--blue {
    color: $shale-blue;
    &:hover {
      color: $blue;
      background-color: transparent;
    }
  }

  &__text + &__icon,
  &__icon + &__text {
    margin-left: 0.5rem;
  }

  &__icon {
    position: relative;
    font-size: 26px;
    transform: rotate(180deg);
    transition: all 0.5s ease-out;
  }
}

// Misc overrides
.main-content {
  a.button::after {
    display: none;
  }
}
