@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__hero-carousel {
  // padding-bottom: $spacer*3;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
      padding-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    .container {
      position: relative;
    }
  }

  .container-lg {
    @media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, lg)) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include media-breakpoint-down(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }
    
  .control-prev,
  .control-next {
    border: none;
    background-color: transparent;
		height: 40px;

    &-icon {
      text-align: center;
      margin: auto;
      padding: 20px;
      width: 40px;
      height: 40px;
      background-size: 40px;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .control-prev-icon {
    background-image: url("./../../../images/circle_arrow_back.svg");
  }

  .control-next-icon {
    background-image: url("./../../../images/circle_arrow_next.svg");
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 53% 47%;
    grid-template-rows: auto auto;
    position: relative;
  }

  &__bottom-placeholder {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-top: -135px;
    bottom: 0;
    padding-top: 175px;
    background-color: var(--sp-color-secondary);
    z-index: -1;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__image {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    transition: 0.25s opacity;

    @include media-breakpoint-down(lg) {

    }

    .lazyload-wrapper img {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  &__image-wrapper {
    @include media-breakpoint-down(lg) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
  }

  &__info-wrapper {
    grid-column: 1 / 3;
  }

  &__info-container {
    @include media-breakpoint-down(lg) {
      padding: 0 var(--bs-gutter-x, 0.85rem) 0;
      margin-left: calc(var(--bs-gutter-x, 0.85rem) * -1);
      margin-right: calc(var(--bs-gutter-x, 0.85rem) * -1);
      background-color: unset;
    }
  }

  &__info {
    width: 100%;
    background-color: var(--sp-color-primary);
    padding: $spacer;
    min-height: 150px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;

    @include media-breakpoint-down(lg) {
      padding: $spacer $spacer*0.5 $spacer*0.5 $spacer*0.5;
      position: relative;
    }
  }  

  &__content {
    flex-grow: 1;
    color: $white;
    display: flex;
    flex-direction: column;
    padding: 0 $spacer;

    @include media-breakpoint-down(sm) {
      padding: 0 $spacer*0.5;
    }

    .eyebrow {
      text-align: left;
      color: $yellow;
      font-size: 1.1rem;
      margin-bottom: $spacer*0.33;
      padding-top: 5px;
      padding-bottom: 2px;
    }

    .heading {
      
      .paragraph__hero-carousel__icon {
        color: #FFFFFF;
        font-size: 26px;
        position: relative;
        transform: rotate(180deg);
        transition: all .5s ease-out;
      }

      &:hover {
        .paragraph__hero-carousel__icon {
          transform: translateX(7px) rotate(180deg);
        }
      }

      a {
        color: $white;

        &:visited {
          color: $white;
        }

        &:hover, &:active {
          text-decoration: none;
        }
      }
    }

    h3 {
      text-align: left;
      text-transform: unset;
      color: $white;
      font-weight: 600;
      line-height: 1.3;
      @include font-size(26px);
      margin: 0;
    }

    a.button {
      text-align: left;
      color: $white;
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 10px 0 16px 0;
      margin-bottom: 5px;

      svg {
        color: $white;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__title-list {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    overflow: hidden;

    @include media-breakpoint-down(lg) {
      max-height: 306.69px;
    }

    .nav-item {
      border-top: 2px solid var(--sp-color-neutral-accent-one);
      margin: 0;
      padding: 0;
      
      &:first-of-type {
        border-top: none;
      }
    }
    
    .nav-link {
      font-size: 1.1rem;
      line-height: 1.7;
      text-decoration: none;
      margin-bottom: 0;
      padding: 1.35rem 1.727rem;

      &.active {
        color: $white;
        background-color: var(--sp-color-secondary);
      }

      &:hover {
        cursor: default;
      }

      @include media-breakpoint-down(xxl) {
        padding: 1.25rem 1.727rem;
      }
      
      @include media-breakpoint-down(lg) {
        padding: $spacer*0.66;
        font-size: 0.95rem;
      }

    }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}