@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__social-media {

  .heading {
    font-size: 26px;
    font-weight: 600;
    line-height: 40px;
    text-transform: uppercase;
  }

  iframe {
    width: 100%;
  }

}
