@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.paragraph__pullquote {
  padding: $spacer $spacer * 3 0;
  border: solid 8px var(--sp-color-neutral-accent-one);
  width: 98%;
  margin: $spacer 0 $spacer * 4;
  text-align: center;
  // background: url("./../../../images/quote_icon.svg") center $spacer * 1.5
  //   no-repeat;
  // background-size: 40px;
  box-shadow: 40px 40px var(--sp-color-secondary);

  @include media-breakpoint-down(md) {
    box-shadow: 40px 40px var(--sp-color-secondary),
      -40px 40px var(--sp-color-secondary);
    padding: $spacer * 2.5 $spacer 0;
  }

  p {
    color: var(--sp-color-primary);
    @include font-size(30px);
    line-height: 140%;
    font-style: italic;
    font-weight: 300;
    margin-bottom: $spacer;
    -webkit-font-smoothing: antialiased;

    &.pullquote__attribution {
      font-family: var(--sp-font-secondary);
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 1px;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: $spacer * 1.5;
    }
  }

  p:first-of-type:before {
    width: 44px;
    height: 32px;
    display: block;
    margin: $spacer auto;
    content: '';
    -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 32'%3E%3Cpath d='M10.074 32c4.444 0 7.704-2.815 7.704-7.26 0-6.221-8.297-5.48-8.297-10.962 0-7.556 9.778-10.519 9.778-12.445C19.26.593 18.815 0 17.63 0 16 0 0 6.667 0 20.444 0 27.704 5.185 32 10.074 32zm24.445 0c4.444 0 7.703-2.815 7.703-7.26 0-6.221-8.296-5.48-8.296-10.962 0-7.556 9.778-10.519 9.778-12.445 0-.74-.445-1.333-1.63-1.333-1.63 0-17.63 6.667-17.63 20.444C24.444 27.704 29.63 32 34.52 32z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 32'%3E%3Cpath d='M10.074 32c4.444 0 7.704-2.815 7.704-7.26 0-6.221-8.297-5.48-8.297-10.962 0-7.556 9.778-10.519 9.778-12.445C19.26.593 18.815 0 17.63 0 16 0 0 6.667 0 20.444 0 27.704 5.185 32 10.074 32zm24.445 0c4.444 0 7.703-2.815 7.703-7.26 0-6.221-8.296-5.48-8.296-10.962 0-7.556 9.778-10.519 9.778-12.445 0-.74-.445-1.333-1.63-1.333-1.63 0-17.63 6.667-17.63 20.444C24.444 27.704 29.63 32 34.52 32z'/%3E%3C/svg%3E") no-repeat 50% 50%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    background-color: var(--sp-color-primary);
  }
}

blockquote {
  @extend .paragraph__pullquote;

  box-shadow: none;
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .paragraph__pullquote {
    padding: $spacer * 2.5 $spacer 0;
    box-shadow: 20px 20px var(--sp-color-secondary);

    @include media-breakpoint-down(md) {
      box-shadow: 40px 40px var(--sp-color-secondary),
        -40px 40px var(--sp-color-secondary);
      padding: 120px 20px;
    }
  }
}
