@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.iconcard {
  max-width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__content {
    padding: $spacer*1.5;
  }

  img {
    display: block;
    max-width: 164px;
    width: 100%;
    height: auto;
  }

}

.iconcard, .button span {
  font-size: 14px;
  line-height: 24px;
}
