@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.duke-alert {
  font-family: var(--sp-font-secondary);
  padding: $spacer;
  text-align: left;
  position: relative;
  margin: 5px auto 10px auto;
  line-height: 18px;

  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }

  &.alert-0 {
    display: none;
  }

  h2 {
    margin: 0 0 15px 0 !important;
    font-family: var(--sp-font-secondary);
    padding: 0;
    background: none;

    &.dukealert-standardHeadline {
      font-family: var(--sp-font-secondary) !important;
      font-size: 24px;
      height: 18px;
      text-transform: uppercase;
    }
  }

  a {
    color: #ffffff;
    text-decoration: none;
    outline: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
  &-0 {
    display: none;
  }

  ul {
    margin: 5px 5px 10px 0;
  }

  li {
    padding-left: 0;
    list-style-type: disc;
    background-image: none;
    margin-left: 30px;
  }

  h2,
  strong {
    color: #fff;
  }

  .dukealert-header {
    width: 20%;
    min-width: 180px;
    float: left;
    padding: 5px 10px;
  }

  .dukealert-description {
    border-left: 1px solid #c15752;
    float: left;
    width: 70%;
    padding: 5px 0 0 20px;
    margin-top: 10px;

    a {
      color: #ffffff !important;
      text-decoration: none;
      border-bottom: 1px dotted;

      &:hover {
        color: #ffffff !important;
        text-decoration: none;
        border-bottom: 1px solid;
      }
    }
  }

  .dukealert-alertLink {
    display: inline-block;
    height: 30px;
    color: #fff;
    font-weight: 700;
    line-height: 30px;
    color: #eeeeee;
    text-transform: uppercase;
    font-size: 13px;
    padding: 0 8px 0 8px;
  }

  @media screen and (max-width: 767px) {
    .dukealert-header {
      width: 100% !important;
    }

    .dukealert-description {
      width: 100%;
      border-left: 0;
      padding-left: 10px;
    }
  }

  &-lev1 {
    color: #ffcccc;
    background: #ad1e17
      url(https://alertbar.oit.duke.edu/sites/all/themes/blackwell/images/struct/bg-alert.png)
      bottom center repeat-x;
      .dukealert-description {
  
        a[href*="http"]:not([href*="-files"]):not(.hide-icon):not(:has(img)) {
          &::after {
            content: url("./../../../images/external-link-icon-white.svg");
          }
        }
      }
  
    h2.dukealert-standardHeadline {
      font-size: 34px !important;
      height: 30px;
    }

    .dukealert-alertLink {
      background: url(https://alertbar.oit.duke.edu/sites/all/themes/blackwell/images/struct/btn-alert_red.png)
        0 0 no-repeat;

      &:hover {
        background-position: 0 -30px;
        text-decoration: none;
      }

      span {
        background: url(https://alertbar.oit.duke.edu/sites/all/themes/blackwell/images/struct/btn-alert_red.png)
          top right no-repeat;
        color: #cbb3b3;
      }

      &:hover span {
        background-position: right -30px;
        text-decoration: none;
      }
    }
  }

  &-lev2 {
    color: #262626;
    background: #ee9827
      url(https://alertbar.oit.duke.edu/sites/all/themes/blackwell/images/struct/bg-alert-2.png)
      bottom center repeat-x;

    .dukealert-description {
      border-left: 1px solid #262626;
      color: #262626;

      a[href*="http"]:not([href*="-files"]):not(.hide-icon):not(:has(img)) {
        &::after {
          content: url("./../../../images/external-link-icon-black.svg");
        }
      }
    a,
    a:hover {
      color: #262626 !important;
      }
    }

    h2.dukealert-standardHeadline {
      color: #262626;
    }

    .dukealert-alertLink {
      background: url(https://alertbar.oit.duke.edu/sites/all/themes/blackwell/images/struct/btn-alert_orange.png)
        0 0 no-repeat;

      span {
        background: url(https://alertbar.oit.duke.edu/sites/all/themes/blackwell/images/struct/btn-alert_orange.png)
          top right no-repeat;
        color: #785a1e;
      }

      &:hover {
        background-position: 0 -30px;
        text-decoration: none;

        & span {
          background-position: right -30px;
          text-decoration: none;
        }
      }
      
      &::after{
        content: url("./../../../images/external-link-icon-brown.svg") !important;
      }
    }
  }

  &-lev3 {
    color: #012169 !important;
    background-color: #fff !important;
    border: 1px solid #e5e5e5 !important;
    overflow: hidden;

    .dukealert-description {
      border-left: none;
      max-width: 1200px;
      width: auto;
      padding-left: 100px;
      padding-right: 20px;
      padding-bottom: 5px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      float: none;
      font-size: 15px;
      font-weight: 400;
      position: relative;

      &:before {
        content: "";
        background-image: url(https://alertbar.oit.duke.edu/sites/all/themes/blackwell/images/struct/circle-right.svg);
        background-repeat: no-repeat;
        opacity: 0.07;
        position: absolute;
        // top: 50%;
        transform: translateY(-50%);
        left: 30px;
        background-size: 40px;
        height: 40px;
        width: 40px;
      }

      strong {
        color: #012169 !important;
      }

      a,
      a:hover,
      a:focus,
      a:visited,
      a:active {
        color: #012169 !important;
      }

      a[href*="http"]:not([href*="-files"]):not(.hide-icon):not(:has(img)) {
        &::after {
          content: url("./../../../images/external-link-icon-blue.svg"); 
          display: inline-block;
          margin-left: 5px;
        }
      }

      p {
        color: #012169 !important;
        margin-bottom: 10px !important;

        &:last-of-type {
          margin-bottom: 0 !important;
        }
      }
    }

    .dukealert-heading {
      color: #012169 !important;
      font-size: 24px;
      line-height: 26px;
      font-weight: 400;
    }
  }

  @media screen and (max-width: 768px) {
    &-lev3 {
      padding: 0;
    }
    &-lev3 .dukealert-description {
      padding-left: 20px;
    }
    &-lev3 .dukealert-description:before {
      left: 0;
    }
  }
}
