@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.teasercard {
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  padding: $spacer;

  @include media-breakpoint-up(lg) {
    padding: $spacer 0;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &__meta {
    font-family: var(--sp-font-secondary);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .9rem;
    font-weight: bold;
    line-height: 1.71;

    .date, p {
      display: inline-block;
    }

    p {
      margin-bottom: 0;
    }
  }

  .heading--teasercard {
    @include heading-base;
    margin: $spacer*0.33 0;
    font-weight: 600;
    font-size: 20px;

    a {
      color: var(--sp-color-secondary);
    }
  }
}

div[class^="col-"] .teasercard {
  .heading--teasercard {
    font-size: 1.1rem;
  }
}
