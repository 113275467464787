@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.main-content--policy {
  h1 {
    margin-top: 0;
  }
}

.policy {
  &-sidebar {
    background-color: #EDF8F2;
    padding: $spacer;

    h3 {
      font-size: 14px;
      color: var(--sp-color-primary);
      line-height: 1;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
 
  &-status {
    font-style: italic;
    margin-top: 0;
    font-family: var(--sp-font-secondary);
  }
  .category {
    font-family: var(--sp-font-secondary);
    font-size: 14px;
    color: var(--sp-color-text);
  }
}

.node-teaser__policy {
  padding-bottom: 1.1rem;
  border-bottom: solid 1px var(--sp-color-neutral-accent-one);
  word-break: break-word;
  margin-bottom: 15px;

  // &:last-of-type {
  //   border-bottom: none;
  // }
}

.embedded-results, .paragraph__content-reference {
  .node-teaser__policy:last-of-type {
    border-bottom: none;
  }
}

.paragraph__twocolumn,
.paragraph__threecolumn {
  .col-md-4 {
    .paragraph__policylist {
      .node-teaser__policy {
        flex-direction: column;

        .col-lg-4,
        .col-lg-8 {
          width: 100%;
          // padding-bottom: $spacer * 1.5;
          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
}
