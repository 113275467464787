@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.mainmenu {
  background-color: var(--sp-color-header-secondary);
  padding-left: $spacer;
  padding-top: $spacer;

  @include media-breakpoint-up(lg) {
    background-color: var(--sp-color-menu-main-background);
    padding: 0;
    margin-top: 0;
  }

  &.navbar-nav > .navbar-nav {
    justify-content: flex-start;
    border-top: solid 1px var(--sp-color-header-divider);

    &:empty {
      border-top: none;
    }
  }

  .container.collapse:not(.show) {
    display: none;
  }

  .nav-item {
    list-style: none;
    font-family: var(--sp-font-secondary);
    font-weight: 600;
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
    }

    &.show {
      color: var(--sp-color-menu-main-link-active);
      @include media-breakpoint-up(lg) {
        background-color: var(--sp-color-menu-main-background);
      }
    }

    & > .nav-link {
      display: block;
      padding: $spacer*0.25;
      color: var(--sp-color-menu-main-link-mobile);
      font-size: 1.7rem;
      font-weight: normal;
      cursor: pointer;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-appearance: none;

      &:after {
        display: none;
        @include media-breakpoint-up(lg) {
          display: inline-block;
          color: var(--sp-color-menu-main-caret);
          margin-left: 0.5em;
        }
      }

      &:hover,
      &:focus {
        @include media-breakpoint-up(lg) {
          color: var(--sp-color-menu-main-link-hover);
          background-color: var(--sp-color-menu-main-background-hover);
          transition: background-color 300ms linear;

          &:after {
            color: var(--sp-color-menu-main-link-hover);
          }
        }  
      }

      &.active, &[aria-current="page"] {
        @include media-breakpoint-up(lg) {
          color: var(--sp-color-menu-main-link-active);

          &:hover {
            color: var(--sp-color-menu-main-link-active-hover);
          }
        }  
      }

      @include media-breakpoint-up(lg) {
        color: var(--sp-color-menu-main-link);
        font-size: 1rem;
        font-weight: 700;
        padding: $spacer*0.8 $spacer;
      }
    }

    &.dropdown {
      position: initial;

      &.nav-item {
        margin-right: $spacer;
				padding-right: $spacer;

				@include media-breakpoint-up(lg) {
					margin-right: 0;
					padding-right: 0;
				}
      }
    }
  }
}

.mainmenu-background-bar {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw; 
  background-color: var(--sp-color-menu-main-background);
  z-index: -100;
}

.dropdown-menu {
  position: relative;
  z-index: 200;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  padding: 0;
  margin: 0;

  &[data-bs-popper] {
    margin-top: 0;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 200;
    background-color: var(--sp-color-header-secondary);
    box-shadow: inset 0 4px 12px 0 rgba(0, 0, 0, 0.4),
      0 4px 12px 0 rgba(0, 0, 0, 0.4);
    left: 0;
    right: 0;
  }

  h2 {
    color: var(--sp-color-accent-one);
  }

  .container {
    display: flex;
    justify-content: center;
    padding: 0;
    max-width: 100%;
    
    @include media-breakpoint-up(lg) {
      padding: $spacer*2 0;
      max-width: 100rem;
    }
  }

  .dropdown-links {
    position: relative;
    z-index: 200;
    background-color: transparent;
    transform: none;
    border: none;
    width: 100%;

    &:after {
      content: '';
      width: 100%;
      display: inline-block;
    }

    @include media-breakpoint-up(lg) {
      margin-left: 2.2em;
      // width: calc(100vw - 2.2em);
      width: min-content;
      // max-height: 50vh;
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
  }

  .dropdown-item {
    white-space: pre-wrap;
  }

  .dropdown-item.nav-link {
    border-left: none;
    padding: $spacer*0.66 $spacer;
    color: var(--sp-color-menu-main-dropdown-link-mobile);
    background-color: transparent;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
    -webkit-appearance: none;

    @include media-breakpoint-up(lg) {
      width: 100%;
      min-width: 300px;
      display: inline-block;
      border-left: 1px solid var(--sp-color-accent-one);
      padding: $spacer*0.66 $spacer * 2.5 $spacer*0.66 $spacer * 1.5;
      line-height: 1.4;
      color: var(--sp-color-menu-main-dropdown-link);

      &:active,
      &:focus {
        background-color: transparent;
      }

      &:hover,
      &:focus-visible {
        background-image: none;
        text-decoration: underline;
      }
    } 
  }  
}

// mobile styles
@include media-breakpoint-down(md) {

  .dropdown-link {
    padding: $spacer*0.2 1.2rem;
  }
  // toggle - change to +/-, align to right
  .dropdown-toggle {
    position: relative;

    .dropdown-caret {
      cursor: pointer;
      height: 16px;
      position: absolute;
      top: 30px;
      right: 5px;
      width: 16px;
      border: none;

      &:before,
      &:after {
        background: var(--sp-color-menu-main-link-mobile);
        content: '';
        height: 2px;
        right: 5px;
        position: absolute;
        top: 0;
        width: 16px;
        border: none;
        transition: transform 0.2s ease;

        @include media-breakpoint-up(lg) {
          background: var(--sp-color-accent-one);
        }
      }

      &:after {
        transform-origin: center;
        background: var(--sp-color-menu-main-link-mobile);
        @include media-breakpoint-up(lg) {
          background: var(--sp-color-accent-one);
        }
      }
    }

    &[aria-expanded="false"] span {
      &:after {
        transform: rotate(90deg);
      }
      &:before {
        transform: rotate(180deg);
      }
    }
  }
} 

// Hamburger menu label (MENU)
.navbar-hamburger-button_label {
  display: inline-block;
  font-size: 0.9rem;
  color: var(--sp-color-menu-main-link);
  font-family: var(--sp-font-secondary);
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 10px;
  padding-top: 5px;
  letter-spacing: 1px;
}

.navbar-toggler {
  display: block;
  border: none;
  border-radius: 0;
  background-color: var(--sp-color-menu-main-background);
  margin: 0;
  padding: $spacer*0.5;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: transform 0.2s ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: var(--sp-color-menu-main-link);
  width: 100%;
  border-top: solid 1px var(--sp-color-secondary);

  &:focus {
    outline: 2px solid white;
  }


  .hamburger-box {
    width: 20px;
    height: 11px;
    display: inline-block;
    position: relative;
    margin-bottom: 2px;
  }

  .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    top: 50%;

    &,
    &::before,
    &::after {
      width: 100%;
      height: 2px;
      content: "";
      display: block;
      background-color: var(--sp-color-menu-main-link);
      border-radius: 1px;
      position: absolute;
      transition: transform 0.2s ease;
    }

    &::before {
      top: -5px;
      transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out,
        background-color 0.15s ease;
    }

    &::after {
      bottom: -5px;
      transition: bottom 0.1s 0.34s ease-in,
        transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19),
        background-color 0.15s ease;
    }
  }

  &[aria-expanded="false"] {
    .label-close {
      display: none;
    }
  }

  &[aria-expanded="true"] {
    .label-menu {
      display: none;
    }

    .hamburger-inner {
      transform: rotate(225deg);
      transition-delay: 0.2s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        background-color: $white;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.2s ease-out,
          transform 0.3s 0.24s cubic-bezier(0.215, 0.61, 0.355, 1),
          background-color 0.15s ease;
      }
    }
  }
}

// Skin-specific variations
.navbar-dark {
  .navbar-toggler {
    border-color: var(--sp-color-secondary);
  }
}

// Dark skin
header {

  &.dark {
    #primary-navigation {
      color: $white;
    }
  }

  // Light skin
  &.light {
    .mainmenu .nav-item .nav-link {
      @include media-breakpoint-up(lg) {
        color: var(--sp-color-primary);
      }
    }    
    .mainmenu .dropdown-menu .dropdown-item.nav-link,
    .mainmenu .nav-item.show.dropdown .nav-link {
      @include media-breakpoint-up(lg) {
        color: $white;
      }
    }

  }  
}  