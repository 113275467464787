@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";


.paragraph__accordion {
  font-family: var(--sp-font-secondary);
  margin-bottom: $spacer*2;

  h3 {
    line-height: 40px;
    font-weight: 600;
  }
}
