@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
.search-interface {
  padding-bottom: 4em;

  .top-controls {
    display: flex;
    flex-flow: row nowrap;
    .search-form {
      margin-right: $spacer;
    }
  }

  input[type="search"],
  select.custom-select {
    width: 300px;
    margin-right: 0;
    border-radius: 0;
    border-right: 0;
    background-color: $off-white;
    border-color: $between-med-light-gray;
    font-family: var(--sp-font-secondary);
    background-clip: unset;
    padding: 0.375rem 0.75rem;
    height: calc(1.7em + 0.75rem + 2px);

    &:-internal-autofill-selected {
      background-color: $off-white !important;
    }

    &:focus {
      box-shadow: none;
    }
  }

  select.custom-select {
    border-right: solid 1px $between-med-light-gray;
    height: 41px;
  }

  button[type="submit"] {
    border-radius: 0;
    border-left: 0;
    background-color: $off-white;
    border-color: $between-med-light-gray;
    color: var(--sp-color-primary);
    height: calc(1.7em + 0.75rem + 2px);

    svg {
      width: 24px;
      height: 24px;
    }
  }

  button[type="reset"] {
    margin: 0 0 0 $spacer;
    padding: 0.32rem 0.75rem;
  }

  .search-form {
    margin-bottom: 1.5em;
    font-family: var(--sp-font-secondary);

    form {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
    }
  }

  .search-sort {
    width: initial;
  }

  .spinner-border {
    margin: 0 $spacer;
  }

  .search-facets {
    margin: 0 0 $spacer 0;

    &.meta-box {
      padding: 0 $spacer $spacer;
      margin-bottom: $spacer * 2;

      @include media-breakpoint-up(md) {
        padding: 0 $spacer * 2 $spacer * 2 $spacer * 2;
      }
    }

    .collapse-facets-btn {
      background-color: $white;
      border: none;
      font-family: var(--sp-font-secondary);
      font-weight: 700;
      font-size: 0.9rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: var(--sp-color-secondary);
      box-shadow: none;
      padding: 0 30px 5px 0;
      width: 100%;
      position: relative;
      border-bottom: solid 4px $yellow;
      border-radius: 0;
      text-align: left;
      margin-bottom: 10px;

      .plus-minus-toggle {
        cursor: pointer;
        height: 100%;
        position: absolute;
        width: 21px;
        top: 8px;
        right: 0px;

        &:before,
        &:after {
          background: var(--sp-color-secondary);
          content: "";
          height: 3.2px;
          left: 0;
          position: absolute;
          top: 0;
          width: 20px;
          transition: transform 150ms ease;
        }

        &:after {
          transform-origin: center;
        }

        &.collapsed {
          &:after {
            transform: rotate(90deg);
          }
          &:before {
            transform: rotate(180deg);
          }
        }
      }

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .collapse:not(.show) {
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }

  .search-facet-group {
    margin-bottom: $spacer;

    h3 {
      @include font-size(20px);
      margin-top: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0 !important;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        &.form-check {
          display: flex;
          align-items: flex-start;
        }
      }

      input[type="checkbox"] {
        margin-right: 0.75em;
        margin-top: 0.4rem;
      }

      label {
        .value {
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
        .count {
          font-weight: bold;
          margin-left: 0.25em;
        }
      }
    }

    button {
      border: none;
      background: none;
      color: $dark-blue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .search-results {
    ul, li {
      list-style-type: none;
      margin-block-start: 0;
      padding-inline-start: 0;
    }

    .result {
      margin-bottom: 1.8em;
      em {
        font-weight: bold;
        color: black;
      }

      .date {
        font-style: italic;
      }

      .title {
        font-family: var(--sp-font-secondary);
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.4em;
      }
    }
  }

 

  &.profile-search {
    .search-pagination {
      justify-content: center;
    }
  }
}

.search-pagination {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin-top: $spacer;

  @include media-breakpoint-up(lg) {
    flex-flow: row nowrap;
  }

  .pages {
    display: flex;
    flex-flow: row wrap;

    @include media-breakpoint-up(lg) {
      flex-flow: row nowrap;
    }
  }
  .pagination-link {
    font-family: var(--sp-font-secondary);
    margin: 0 10px;
    font-size: 16px;
    line-height: 32px;
    font-weight: bold;
    height: 32px;
    width: 32px;
    text-align: center;
    border: none;
    background-color: transparent;

    &.active {
      background-color: var(--sp-color-primary);
      color: white;
      border-radius: 32px;
    }

    &:hover {
      border-radius: 32px;
    }
  }

  .increment {
    &.next {
      .pagination-link {
        &:after {
          content: "\2192";
          margin-left: 10px;
        }
        &:hover {
          border-radius: 0;
        }
      }
    }
    &.previous {
      .pagination-link {
        &:before {
          content: "\2190";
          margin-right: 10px;
        }
        &:hover {
          border-radius: 0;
        }
      }
    }
  }
}

.pagination-showing-info {
  font-family: var(--sp-font-secondary);
}