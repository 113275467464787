/*
  Mixin for the offset background
*/
@mixin offsetBackground($color) {
  background: -webkit-linear-gradient(180deg, $white $spacer*2, $color $spacer*2);
  background: -o-linear-gradient(180deg, $white $spacer*2, $color $spacer*2);
  background: -moz-linear-gradient(180deg, $white $spacer*2, $color $spacer*2);
  background: linear-gradient(180deg, $white $spacer*2, $color $spacer*2);
}

// Heading base
@mixin heading-base {
  font-family: var(--sp-font-secondary);
  color: var(--sp-color-primary);
  margin: $spacer 0 $spacer*0.33;
}

// Lists heading
@mixin list-heading {
  border-bottom: 1px solid var(--sp-color-neutral-accent-one);
  margin-bottom: $spacer;

  h2 {
    display: inline-block;
    border-bottom: 4px solid $yellow;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 7px;
    padding-top: 7px;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    word-break: break-word;

    @include media-breakpoint-up(lg) {
      @include font-size(26px);
    }
  }  
}
